<template>
    <el-container>
        <el-main>
            <el-card shadow="never">
                <el-form ref="form" :model="form" label-width="90px">
                    <el-row :gutter="10">
                        <el-col :span="6" :xs="24">
                            <el-form-item label="appId"><el-input v-model="form.appId" clearable
                                    placeholder="请输入appId"></el-input></el-form-item>
                        </el-col>
                        <el-col :span="6" :xs="24">
                            <el-form-item label="app名称"><el-input v-model="form.name" clearable
                                    placeholder="请输入app名称"></el-input></el-form-item>
                        </el-col>
                        <el-col :span="6" :xs="24">
                            <el-form-item label="所属主体"><el-input v-model="form.state" clearable
                                    placeholder="请输入所属主体"></el-input></el-form-item>
                        </el-col>
                        <el-col :span="6" :xs="24">
                            <el-form-item label="平台">
                                <el-select v-model="form.platform" placeholder="请选择平台" clearable>
                                    <el-option label="app" :value="0" />
                                    <el-option label="小程序" :value="1" />
                                    <el-option label="网页" :value="2" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="top_l">
                        <el-col :span="24" :xs="24">
                            <el-button type="primary" @click="jouShare">搜索</el-button>
                            <el-button type="primary" style="margin-left: 20px;"
                                @click="dialogAdd = true">新增</el-button>
                            <el-button type="primary" style="margin-left: 20px;" @click="refreshCache">刷新缓存</el-button>
                        </el-col>
                    </el-row>
                </el-form>
                <!-- table 表格 -->
                <el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }" border
                    stripe>
                    <el-table-column prop="appId" label="appId"></el-table-column>
                    <el-table-column prop="name" label="名称"></el-table-column>
                    <el-table-column label="平台">
                        <template v-slot="scope">
                            <span v-if="scope.row.platform == 0">app</span>
                            <span v-else-if="scope.row.platform == 1">小程序</span>
                            <span v-else-if="scope.row.platform == 2">网页</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="应用私钥" width="300">
                        <template #default="scope">
                            <el-popover effect="light" trigger="hover" placement="top" width="auto">
                                <template #default>
                                    <div style="width: 400px;"> {{ scope.row.alipaySelfPri }}</div>
                                </template>
                                <template #reference>
                                    <div class="tablecell">{{ scope.row.alipaySelfPri }}</div>
                                </template>
                            </el-popover>
                        </template>
                    </el-table-column>

                    <el-table-column label="支付宝公钥" width="300">
                        <template #default="scope">
                            <el-popover effect="light" trigger="hover" placement="top" width="auto">
                                <template #default>
                                    <div style="width: 400px;"> {{ scope.row.alipayAliPub }}</div>
                                </template>
                                <template #reference>
                                    <div class="tablecell">{{ scope.row.alipayAliPub }}</div>
                                </template>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column prop="limitCharge" label="收款限额（元）"></el-table-column>
                    <el-table-column prop="state" label="所属主体"></el-table-column>
                    <el-table-column prop="createTime" label="创建时间"></el-table-column>
                    <el-table-column prop="updateTime" label="更新时间"></el-table-column>
                    <el-table-column label="操作" align="center">
                        <template v-slot="scope">
                            <el-button size="small" type="primary" plain @click="clcedit(scope.row)">编辑</el-button>
                            <el-button size="small" type="primary" plain @click="clcedetele(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                    <template #empty>
                        <el-empty :description="emptyText" :image-size="100"></el-empty>
                    </template>
                </el-table>
                <!-- 分页 -->
                <div class="pagin">
                    <el-pagination :small="true" v-model:currentPage="currentPage" v-model:page-size="pageSize"
                        :page-sizes="[10, 20, 50, 100]" background layout="total, sizes, prev, pager, next, jumper"
                        :total="+totals" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
                </div>
                <!-- 新增-产品 -->
                <el-dialog v-model="dialogAdd" title="新增" width="50%" :fullscreen="fullscreenshow">
                    <el-form :model="ruleForm" :rules="rules" ref="addruleForm" label-width="180px"
                        class="demo-ruleForm">
                        <el-row :gutter="20">
                            <el-col :span="12" :xs="24">
                                <el-form-item label="appId" prop="appId"><el-input placeholder="请输入appId"
                                        v-model="ruleForm.appId"></el-input></el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="名称" prop="name"><el-input placeholder="请输入名称"
                                        v-model="ruleForm.name"></el-input></el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="平台" prop="platform">
                                    <el-select v-model="ruleForm.platform" placeholder="请输入平台">
                                        <el-option label="app" :value="0" />
                                        <el-option label="小程序" :value="1" />
                                        <el-option label="网页" :value="2" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="收款限额（元）" prop="limitCharge">
                                    <el-input-number style="width: 100%;" v-model="ruleForm.limitCharge" :precision="2"
                                        :step="0.1" :min="0" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="应用私钥" prop="alipaySelfPri"><el-input :rows="6" type="textarea"
                                        placeholder="请输入应用私钥"
                                        v-model="ruleForm.alipaySelfPri"></el-input></el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="支付宝公钥" prop="alipayAliPub"><el-input :rows="6" type="textarea"
                                        placeholder="请输入支付宝公钥"
                                        v-model="ruleForm.alipayAliPub"></el-input></el-form-item>
                            </el-col>

                            <el-col :span="12" :xs="24">
                                <el-form-item label="所属主体" prop="state"><el-input placeholder="请输入所属主体"
                                        v-model="ruleForm.state"></el-input></el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <template #footer>
                        <span class="dialog-footer">
                            <el-button type="primary" @click="submitClick('addruleForm')">提交</el-button>
                            <el-button @click="resetClick('addruleForm')">重置</el-button>
                        </span>
                    </template>
                </el-dialog>
                <!-- 修改 -->
                <el-dialog v-model="dialogEdit" title="修改" width="50%" :fullscreen="fullscreenshow">
                    <el-form :model="EditruleForm" :rules="rules" ref="EditruleFormRef" label-width="180px"
                        class="demo-ruleForm">
                        <el-row :gutter="20">
                            <el-col :span="12" :xs="24">
                                <el-form-item label="appId" prop="appId"><el-input placeholder="请输入appId"
                                        v-model="EditruleForm.appId"></el-input></el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="名称" prop="name"><el-input placeholder="请输入名称"
                                        v-model="EditruleForm.name"></el-input></el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="平台" prop="platform">
                                    <el-select v-model="EditruleForm.platform" placeholder="请输入平台">
                                        <el-option label="app" :value="0" />
                                        <el-option label="小程序" :value="1" />
                                        <el-option label="网页" :value="2" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="收款限额（元）" prop="limitCharge">
                                    <el-input-number style="width: 100%;" v-model="EditruleForm.limitCharge"
                                        :precision="2" :step="0.1" :min="0" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="应用私钥" prop="alipaySelfPri"><el-input :rows="6" type="textarea"
                                        placeholder="请输入应用私钥"
                                        v-model="EditruleForm.alipaySelfPri"></el-input></el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="支付宝公钥" prop="alipayAliPub"><el-input :rows="6" type="textarea"
                                        placeholder="请输入支付宝公钥"
                                        v-model="EditruleForm.alipayAliPub"></el-input></el-form-item>
                            </el-col>

                            <el-col :span="12" :xs="24">
                                <el-form-item label="所属主体" prop="state"><el-input placeholder="请输入所属主体"
                                        v-model="EditruleForm.state"></el-input></el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <template #footer>
                        <span class="dialog-footer">
                            <el-button type="primary" @click="EditsubmitForm('EditruleFormRef')">提交</el-button>
                            <el-button @click="dialogEdit = false">取消</el-button>
                        </span>
                    </template>
                </el-dialog>
            </el-card>
        </el-main>
    </el-container>
</template>

<script>
export default {
    name: 'aliAppInfo',
    data() {
        return {
            fullscreenshow: false,
            emptyText: '暂无数据',
            currentPage: 1,
            pageSize: 10,
            tableData: [],
            totals: null,
            serloading: false,
            productId: '',

            form: {
                appId: '',
                name: '',
                state: '',
                platform: '',
            },


            dialogAdd: false,
            ruleForm: {
                name: '',
                appId: '',
                platform: 0,
                alipaySelfPri: '',
                alipayAliPub: '',
                limitCharge: 0,
                state: ''
            },
            rules: {
                appId: [{ required: true, message: '请输入appId', trigger: 'blur' }],
                name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
                alipaySelfPri: [{ required: true, message: '请输入应用私钥', trigger: 'blur' }],
                alipayAliPub: [{ required: true, message: '请输入支付宝公钥', trigger: 'blur' }],
                limitCharge: [{ required: true, message: '请输入收款限额（元）', trigger: 'blur' }],
                state: [{ required: true, message: '请输入所属主体', trigger: 'blur' }],
                platform: [{ required: true, message: '请输入平台', trigger: 'change' }]
            },
            // 编辑
            dialogEdit: false,
            EditruleForm: {
                id: '',
                name: '',
                appId: '',
                platform: 0,
                alipaySelfPri: '',
                alipayAliPub: '',
                limitCharge: 0,
                state: ''
            }
        };
    },
    created() {
        this.productId = this.$TOOL.data.get('DATA_SELECTEED');
        // 列表
        this.aliAppInfolist();

        if (document.body.clientWidth < 768) {
            this.fullscreenshow = true;
        } else {
            this.fullscreenshow = false;
        }
    },
    methods: {
        // 列表
        aliAppInfolist() {
            this.serloading = true;
            this.$HTTP.post(
                'aliAppInfo/query',
                {
                    productId: this.productId,
                    pageNumber: this.currentPage,
                    pageSize: this.pageSize,
                    appId: this.form.appId,
                    name: this.form.name,
                    state: this.form.state,
                    platform: this.form.platform,
                },
                response => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.data.code == 0) {
                            this.tableData = response.data.data.records;
                            this.totals = response.data.data.total;
                        } else {
                            this.$message.error(response.data.msg);
                        }
                    } else {
                        console.log(response.message);
                    }
                    this.serloading = false;

                }
            );
        },
        // 重置
        resetClick(name) {
            this.$refs[name].resetFields();
        },
        // 搜索
        jouShare() {
            this.serloading = true;
            this.currentPage = 1;
            // 列表
            this.aliAppInfolist();
        },
        // 刷新缓存
        refreshCache() {
            this.serloading = true;
            this.$HTTP.post('aliAppInfo/refreshCache', {}, response => {
                if (response.status >= 200 && response.status < 300) {
                    if (response.data.code == 0) {
                        this.$message.success(response.data.msg);
                    } else {
                        this.$message.error(response.data.msg);
                    }
                } else {
                    console.log(response.message);
                }
                this.serloading = false;

            });
        },
        // 新增
        submitClick(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.$HTTP.post(
                        'aliAppInfo/add',
                        {
                            productId: this.productId,
                            name: this.ruleForm.name,
                            appId: this.ruleForm.appId,
                            platform: this.ruleForm.platform,
                            alipaySelfPri: this.ruleForm.alipaySelfPri,
                            alipayAliPub: this.ruleForm.alipayAliPub,
                            limitCharge: this.ruleForm.limitCharge,
                            state: this.ruleForm.state,
                        },
                        response => {
                            if (response.status >= 200 && response.status < 300) {
                                if (response.data.code == 0) {
                                    this.$message.success(response.data.msg);
                                    this.resetClick(formName);
                                    // 列表
                                    this.aliAppInfolist();
                                } else {
                                    this.$message.error(response.data.msg);
                                }
                                this.dialogAdd = false;

                            } else {
                                console.log(response.message);
                            }
                        }
                    );
                } else {
                    return false;
                }
            });
        },

        clcedetele(row) {
            this.serloading = true;
            this.$HTTP.post('aliAppInfo/delete', {
                id: row.id
            }, response => {
                if (response.status >= 200 && response.status < 300) {
                    if (response.data.code == 0) {
                        this.$message.success(response.data.msg);
                        // 列表
                        this.aliAppInfolist();
                    } else {
                        this.$message.error(response.data.msg);
                    }
                } else {
                    console.log(response.message);
                }
                this.serloading = false;

            });
        },
        // 编辑
        clcedit(row) {
            this.EditruleForm.id = row.id;
            this.EditruleForm.appId = row.appId;
            this.EditruleForm.name = row.name;
            this.EditruleForm.platform = row.platform;
            this.EditruleForm.alipaySelfPri = row.alipaySelfPri;
            this.EditruleForm.alipayAliPub = row.alipayAliPub;
            this.EditruleForm.limitCharge = row.limitCharge;
            this.EditruleForm.state = row.state;
            this.dialogEdit = true;
        },
        EditsubmitForm(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.$HTTP.post(
                        'aliAppInfo/update',
                        {
                            id: this.EditruleForm.id,
                            productId: this.productId,
                            name: this.EditruleForm.name,
                            appId: this.EditruleForm.appId,
                            platform: this.EditruleForm.platform,
                            alipaySelfPri: this.EditruleForm.alipaySelfPri,
                            alipayAliPub: this.EditruleForm.alipayAliPub,
                            limitCharge: this.EditruleForm.limitCharge,
                            state: this.EditruleForm.state,
                        },
                        response => {
                            if (response.status >= 200 && response.status < 300) {
                                if (response.data.code == 0) {
                                    this.$message.success(response.data.msg);
                                    // 列表
                                    this.aliAppInfolist();
                                } else {
                                    this.$message.error(response.data.msg);
                                }
                                this.dialogEdit = false;
                            } else {
                                console.log(response.message);
                            }
                        }
                    );
                } else {
                    return false;
                }
            });
        },
        // 分页
        handleSizeChange(val) {
            this.currentPage = 1;
            this.pageSize = val;
            // 列表
            this.aliAppInfolist();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            // 列表
            this.aliAppInfolist();
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tablecell {
    white-space: nowrap; //强制不换行
    overflow: hidden; //溢出隐藏
    text-overflow: ellipsis //替换为省略号
}
</style>
